// extracted by mini-css-extract-plugin
export var acknowledgement = "top-module--acknowledgement--38f3c";
export var background = "top-module--background--704e4";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "top-module--contact--5b3d4";
export var container = "top-module--container--3411d";
export var wrapper = "top-module--wrapper--4f9c5";