"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scrollTo = exports.observeResize = exports.getStyleProperty = exports.getScrollOffsetY = void 0;

var _misc = require("./misc");

const scrollTo = element => {
  if (element instanceof HTMLElement) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }
};

exports.scrollTo = scrollTo;

const getScrollOffsetY = () => {
  if (!_misc.isClient) {
    return 0;
  }

  return typeof window.pageYOffset === "number" ? window.pageYOffset : typeof document.documentElement.scrollTop === "number" ? document.documentElement.scrollTop : document.body.scrollTop;
};

exports.getScrollOffsetY = getScrollOffsetY;

const getStyleProperty = (element, property) => {
  if (!_misc.isClient) {
    return null;
  }

  const computedStyles = window.getComputedStyle(element);
  return computedStyles.getPropertyValue(property);
};

exports.getStyleProperty = getStyleProperty;

const observeResize = (element, callback) => {
  if (typeof MutationObserver === "undefined") {
    const eventName = "DOMSubtreeModified";
    document.addEventListener(eventName, callback);
    return () => document.removeEventListener(eventName, callback);
  }

  if (typeof ResizeObserver === "undefined") {
    const observer = new MutationObserver(callback);
    observer.observe(element, {
      characterData: true,
      attributes: true,
      childList: true,
      subtree: true
    });
    return () => observer.disconnect();
  }

  const observer = new ResizeObserver(callback);
  observer.observe(element);
  return () => observer.disconnect();
};

exports.observeResize = observeResize;