import { Link } from "gatsby";

import { LogoSmallIcon, getProperty } from "@gh/shared";

import { usePageByView } from "hooks";

import * as styles from "./logo-small.module.scss";

export const LogoSmall = () => {
  const page = usePageByView("home");
  const path = getProperty(page, "path");

  return (
    <Link className={styles.wrapper} to={path}>
      <LogoSmallIcon className={styles.icon} />
    </Link>
  );
}
