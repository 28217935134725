import { Container } from "@gh/shared";

import { Logo } from "./logo";
import { NavItems } from "./nav-items";
import { Languages } from "./languages";
import * as renderProps from "./render-props";

import * as styles from "./overlay.module.scss";

const renderMainItem = renderProps.renderNavItem({
  size: "s2",
  className: styles.item,
  activeClassName: styles.active,
});
const renderMiscItem = renderProps.renderNavItem({
  size: "s1",
  className: styles.item,
  activeClassName: styles.active,
});
const renderLanguage = renderProps.renderLanguage({ className: styles.language });

export const Overlay = () => (
  <div className={styles.wrapper}>
    <Container>
      <div className={styles.logo}>
        <Logo animate={false} />
      </div>
      <NavItems menu="main-left">{renderMainItem}</NavItems>
      <NavItems menu="main-right">{renderMainItem}</NavItems>
      <div className={styles.languages}>
        <Languages>{renderLanguage}</Languages>
      </div>
      <NavItems menu="misc">{renderMiscItem}</NavItems>
    </Container>
  </div>
);
