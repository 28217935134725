"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimSlashes = exports.resolvePath = exports.normalizePath = exports.joinPaths = exports.isURL = void 0;

const joinPaths = parts => {
  if (!Array.isArray(parts)) {
    throw new Error("\"paths\" must be an array!");
  }

  parts = parts.map(part => part.trim());
  parts = parts.join("/");
  return resolvePath(parts);
};

exports.joinPaths = joinPaths;

const resolvePath = urlOrPath => {
  if (typeof urlOrPath !== "string") {
    throw new Error("\"urlOrPath\" must be a string!");
  }

  let resolved = urlOrPath;

  try {
    ({
      href: resolved
    } = new URL(urlOrPath));
  } catch (e) {
    resolved = normalizePath(urlOrPath);
  }

  return resolved;
};

exports.resolvePath = resolvePath;

const normalizePath = path => {
  if (typeof path !== "string") {
    throw new Error("\"path\" must be a string!");
  } // resolve relative paths: "abc/../" -> ""


  const regex = /[^/.]+\/+\.\./g;
  let match;

  while (match = path.match(regex)) {
    path = path.replace(match[0], "");
  } // normalize double slashes: "//" -> "/"
  // negative lookbehind to avoid removing
  // slashes in protocols, f.e. http://
  // https://stackoverflow.com/questions/641407


  path = path.replace(/((?![:/]).|^)\/{2,}/g, "$1/"); // remove dot slash paths: "./" -> ""

  path = path.replace(/(?:^\.\/|([^.])\.\/)/g, "$1");
  return path;
};

exports.normalizePath = normalizePath;

const trimSlashes = path => {
  if (typeof path !== "string") {
    throw new Error("\"path\" must be a string!");
  }

  return path.replace(/^[/]*|[/]*$/g, "");
};

exports.trimSlashes = trimSlashes;

const isURL = value => {
  try {
    new URL(value);
  } catch (e) {
    return false;
  }

  return true;
};

exports.isURL = isURL;