import { Link } from "gatsby";
import PropTypes from "prop-types";

import { getProperty } from "@gh/shared";

import { usePagesByMenu } from "hooks";

export const NavItems = ({ menu, children }) => {
  const pages = usePagesByMenu(menu);

  return pages.map((page, index) => {
    const path = getProperty(page, "path");
    const view = getProperty(page, "view");
    const text = getProperty(page, "title");
    const link = <Link key={path} to={path} />;
    const isHighlighted = view === "request";

    return children({
      link,
      text,
      index,
      isHighlighted,
    });
  });
};

NavItems.defaultProps = {
  menu: "misc",
  children: ({ link, text, index }) => (
    <link.type key={index} {...link.props}>
      {text}
    </link.type>
  ),
};

NavItems.propTypes = {
  menu: PropTypes.oneOf(["main-left", "main-right", "misc", "contact"]),
  children: PropTypes.func.isRequired,
};
