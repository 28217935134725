"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useKeyPress = void 0;

var _react = require("react");

const useKeyPress = key => {
  const [isKeyPressed, setIsKeyPressed] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    const downHandler = _ref => {
      let {
        key: currentKey
      } = _ref;

      if (key === currentKey) {
        setIsKeyPressed(true);
      }
    };

    const upHandler = _ref2 => {
      let {
        key: currentKey
      } = _ref2;

      if (key === currentKey) {
        setIsKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [key]);
  return isKeyPressed;
};

exports.useKeyPress = useKeyPress;