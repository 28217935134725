import { useEffect } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import {
  ButtonArrow,
  ButtonStars,
  Title,
  Text,
  Markdown,
  getProperty,
  useTransition,
  Modal,
  Container,
} from "@gh/shared";

import { useContact } from "hooks";

import { Address, Email, Phone } from "./common";

import SVGMap from "./contact/map.svg";

import * as styles from "./contact.module.scss";

export const Contact = () => {
  const contact = useContact();
  const [transitionState, toggleTransition] = useTransition({
    timeout: 750,
  });

  const { hash: currentHash } = useLocation();

  const hash = getProperty(contact, "hash");
  const title = getProperty(contact, "contact.title");
  const content = getProperty(contact, "contact.content");
  const requestPath = getProperty(contact, "contact.request.path");
  const requestText = getProperty(contact, "contact.request.text");
  const linkUrl = getProperty(contact, "contact.link.url");
  const linkText = getProperty(contact, "contact.link.text");
  const directions = getProperty(contact, "contact.directions") || [];

  const closeHandler = () => {
    document.location.hash = "";
  };

  useEffect(() => {
    const normalizedHash = currentHash.substring(1);
    toggleTransition(normalizedHash === hash);
  }, [currentHash, hash]);

  return (
    <Modal
      id={hash}
      onClose={closeHandler}
      extraCloseClassName={styles.close}
      extraContentClassName={styles.content}
      extraScrollerClassName={styles.scroller}
      extraContainerClassName={styles.container}
      extraWrapperClassName={`${styles.wrapper} ${styles[transitionState]}`}
    >
      <Container>
        <div className={styles.holder}>
          <div className={styles.top}>
            <Title size="s4" family="f2" weight="w4" transform="uppercase">
              {title}
            </Title>

            <div className={styles.contact}>
              <Address />
              <Phone />
              <Email />
            </div>

            <div className={styles.request}>
              <ButtonStars wrapper={Link} to={requestPath}>
                {requestText}
              </ButtonStars>
            </div>

            <div className={styles.body}>
              <Markdown content={content} />
            </div>

            <ButtonArrow wrapper="a" href={linkUrl} target="_blank" rel="noreferrer">
              {linkText}
            </ButtonArrow>
          </div>
          <SVGMap className={styles.map} />
          <div className={styles.bottom}>
            {directions.map((direction, index) => {
              const title = getProperty(direction, "title");
              const content = getProperty(direction, "content");

              return (
                <div key={index} className={styles.direction}>
                  <Title wrapper="h3" weight="w4">
                    {title}
                  </Title>
                  <Text>
                    <Markdown content={content} />
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </Modal>
  );
};
