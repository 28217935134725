// extracted by mini-css-extract-plugin
export var actions = "navbar-module--actions--d4d6c";
export var active = "navbar-module--active--b9771";
export var booking = "navbar-module--booking--2b07b";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "navbar-module--contact--4a0f4";
export var container = "navbar-module--container--41ded";
export var icon = "navbar-module--icon--a5890";
export var item = "navbar-module--item--cb9ac";
export var items = "navbar-module--items--20d22";
export var language = "navbar-module--language--b4cf1";
export var languages = "navbar-module--languages--9e114";
export var large = "navbar-module--large--ade5f";
export var left = "navbar-module--left--2466f";
export var logo = "navbar-module--logo--c86b2";
export var open = "navbar-module--open--b9f28";
export var overlay = "navbar-module--overlay--98704";
export var right = "navbar-module--right--1eb46";
export var small = "navbar-module--small--57dd2";
export var svg = "navbar-module--svg--eb766";
export var toggle = "navbar-module--toggle--38904";
export var wrapper = "navbar-module--wrapper--54973";