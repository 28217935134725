// extracted by mini-css-extract-plugin
export var active = "contact-module--active--43b14";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var icon = "contact-module--icon--618b7";
export var large = "contact-module--large--1b1e2";
export var small = "contact-module--small--b6ac2";
export var text = "contact-module--text--f6aa9";
export var wrapper = "contact-module--wrapper--5afe6";