import PropTypes from "prop-types";

import { Text } from "@gh/shared";

import * as styles from "./contact.module.scss";

export const Contact = ({ url, text, ...props }) => (
  <a href={url} className={styles.wrapper} {...props}>
    <Text size="s5" wrapper="span">
      {text}
    </Text>
  </a>
);

Contact.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
