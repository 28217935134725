import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const usePopup = () => {
  const refPopup = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refPopup.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
    refPopup.current = edge;
  }

  refLocale.current = currentLocale;

  return refPopup.current;
};

const query = graphql`
  query usePopupQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "popup" } } }) {
      edges {
        node {
          frontmatter {
            locale
            popup {
              active
              title
              content
              image {
                childImageSharp {
                  gatsbyImageData(width: 900, height: 600, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
