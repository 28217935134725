import { Text, getProperty } from "@gh/shared";

import { useFooter } from "hooks";

import * as styles from "./ref.module.scss";

export const Ref = () => {
  const footer = useFooter();

  const copyright = getProperty(footer, "footer.copyright.text");
  const text = getProperty(footer, "footer.disclaimer.text");
  const url = getProperty(footer, "footer.disclaimer.url");

  return (
    <div className={styles.wrapper}>
      <Text size="s1" className={styles.copyright}>
        {copyright}
      </Text>
      <a href={url} target="_blank" rel="noreferrer" className={styles.link}>
        <Text size="s1" color="black">
          {text}
        </Text>
      </a>
    </div>
  );
};
