import { toPhoneUri, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

import { Contact } from "./contact";

export const Phone = () => {
  const meta = useMeta();
  const url = getProperty(meta, "meta.contact.phone.url");
  const text = getProperty(meta, "meta.contact.phone.text");

  return <Contact url={toPhoneUri(url)} text={`T ${url}`} title={text} />;
};
