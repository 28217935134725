// extracted by mini-css-extract-plugin
export var body = "contact-module--body--8a8f5";
export var bottom = "contact-module--bottom--77cd1";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var close = "contact-module--close--e639d";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var contact = "contact-module--contact--c2fa6";
export var container = "contact-module--container--a8795";
export var content = "contact-module--content--71d6a";
export var direction = "contact-module--direction--46209";
export var entered = "contact-module--entered--54a00";
export var entering = "contact-module--entering--1507b";
export var exited = "contact-module--exited--aca0f";
export var exiting = "contact-module--exiting--1cc7b";
export var holder = "contact-module--holder--28cc2";
export var map = "contact-module--map--db614";
export var request = "contact-module--request--fcd74";
export var scroller = "contact-module--scroller--83132";
export var top = "contact-module--top--0b9de";
export var wrapper = "contact-module--wrapper--e2a6d";