import { Container, Markdown, Text, VillageIcon, getProperty } from "@gh/shared";

import { useFooter } from "hooks";
import { Address, Phone, Email, SocialMedia } from "../common";

import * as styles from "./top.module.scss";

export const Top = () => {
  const data = useFooter();

  const intro = getProperty(data, "footer.intro");
  const acknowledgement = getProperty(data, "footer.acknowledgement");

  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.container}>
          <VillageIcon className={styles.background} />

          <Text family="f2" size="s2" transform="uppercase">
            <Markdown inline={true} content={intro} />
          </Text>

          <div className={styles.contact}>
            <Address />
            <Phone />
            <Email />
          </div>

          <Text size="s2" color="primary" transform="uppercase" className={styles.acknowledgement}>
            <Markdown inline={true} content={acknowledgement} />
          </Text>

          {/* <SocialMedia /> */}
        </div>
      </Container>
    </div>
  );
};
