import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useContact = () => {
  const refContact = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refContact.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
    refContact.current = edge;
  }

  refLocale.current = currentLocale;

  return refContact.current;
};

const query = graphql`
  query useContactQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "contact" } } }) {
      edges {
        node {
          frontmatter {
            hash
            locale
            contact {
              title
              content
              request {
                text
                path
              }
              link {
                text
                url
              }
              directions {
                title
                content
              }
            }
          }
        }
      }
    }
  }
`;
