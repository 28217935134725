"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hashCode = void 0;

const hashCode = string => {
  if (typeof string !== "string") {
    return string;
  }

  let hash = 0;

  for (let i = 0, length = string.length; i < length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }

  return hash;
};

exports.hashCode = hashCode;