// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var dark = "layout-module--dark--87c2b";
export var light = "layout-module--light--0a60a";
export var main = "layout-module--main--e4575";
export var overflow = "layout-module--overflow--e2777";
export var wrapper = "layout-module--wrapper--6d124";