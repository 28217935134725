import { useEffect, useMemo, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

import { Title, Markdown, LogoIcon, getProperty, hashCode, useTransition, Modal } from "@gh/shared";

import { usePopup } from "hooks";

import * as styles from "./popup.module.scss";

const STORAGE_KEY = `___gh_popup_read`;

export const Popup = () => {
  const popup = usePopup();
  const location = useLocation();
  const isMountedRef = useRef(false);

  const [transitionState, toggleTransition] = useTransition({
    preEnter: true,
    timeout: 750,
  });

  const active = getProperty(popup, "popup.active");
  const title = getProperty(popup, "popup.title");
  const content = getProperty(popup, "popup.content");
  const image = getProperty(popup, "popup.image.childImageSharp.gatsbyImageData");

  const hashedValue = useMemo(() => `${hashCode(`${title}.${content}`)}`, [title, content]);

  const closeHandler = () => {
    localStorage.setItem(STORAGE_KEY, hashedValue);
    toggleTransition(false);
  };

  useEffect(() => {
    // make popup visible not only for screenreaders but also users
    // if there's currently no flag set in local storage on device
    const storedValue = localStorage.getItem(STORAGE_KEY);
    toggleTransition(storedValue !== hashedValue);
  }, [hashedValue]);

  useEffect(() => {
    // hide popup in case its shown and user navigates away either
    // by navigation or clicking a reference link within the popup
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }

    closeHandler();
  }, [location.pathname]);

  return active ? (
    <Modal
      onClose={closeHandler}
      extraContentClassName={styles.content}
      extraContainerClassName={styles.container}
      extraWrapperClassName={`${styles.wrapper} ${styles[transitionState]}`}
    >
      <div className={styles.header}>
        <LogoIcon className={styles.svg} />
        <Title size="s2" weight="w5" transform="uppercase">
          {title}
        </Title>
      </div>
      <div className={styles.body}>
        <div className={styles.content}>
          <Markdown content={content} className={styles.text} />
        </div>
        {image && <GatsbyImage image={image} className={styles.image} alt={title} />}
      </div>
    </Modal>
  ) : null;
};
