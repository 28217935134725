import { useMemo } from "react";
import PropTypes from "prop-types";

import { ContainerToLG, RelaxIcon } from "@gh/shared";

import { Logo } from "./logo";
import { LogoSmall } from "./logo-small";
import { Contact } from "./contact";
import { Booking } from "./booking";
import { Overlay } from "./overlay";
import { NavItems } from "./nav-items";
import { Languages } from "./languages";
import { Hamburger } from "./hamburger";

import * as renderProps from "./render-props";
import * as styles from "./navbar.module.scss";

const renderNavItem = renderProps.renderNavItem({
  className: styles.item,
  activeClassName: styles.active,
});
const renderLanguage = renderProps.renderLanguage({ className: styles.language });

export const Navbar = ({ onToggle, isOpen }) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, isOpen ? styles.open : ""].filter(Boolean).join(" "),
    [isOpen]
  );

  return (
    <div className={wrapperClassName}>
      <ContainerToLG>
        <div className={styles.container}>
          <nav className={styles.items}>
            <div className={styles.left}>
              <NavItems menu="main-left">{renderNavItem}</NavItems>
            </div>
            <div className={styles.logo}>
              <div className={styles.small}>
                <LogoSmall />
              </div>
              <div className={styles.large}>
                <Logo />
              </div>
            </div>
            <div className={styles.right}>
              <NavItems menu="main-right">{renderNavItem}</NavItems>
            </div>
            <div className={styles.actions}>
              <div className={styles.booking}>
                <Booking />
              </div>
              <div className={styles.contact}>
                <Contact />
              </div>
            </div>
            <div className={styles.toggle}>
              <Hamburger onClick={onToggle} isActive={isOpen} />
            </div>
          </nav>

          <div className={styles.languages}>
            <Languages>{renderLanguage}</Languages>
          </div>

          <div className={styles.overlay}>
            <Overlay isOpen={isOpen} onToggle={onToggle} />
          </div>
          <div className={styles.icon}>
            <RelaxIcon className={styles.svg} />
          </div>
        </div>
      </ContainerToLG>
    </div>
  );
};

Navbar.defaultProps = {
  isOpen: false,
  onToggle: () => {},
};

Navbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
