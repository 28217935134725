import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useActivitiesLatest = () => {
  const refActivities = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refActivities.current === null || refLocale.current !== currentLocale) {
    refActivities.current = {
      locale: currentLocale,
      activities: {
        items: edges
          .filter((edge) => currentLocale === getProperty(edge, "node.frontmatter.locale"))
          .map((edge) => ({
            ...getProperty(edge, "node.frontmatter.activity"),
            ...getProperty(edge, "node.frontmatter.meta"),
            path: getProperty(edge, "node.frontmatter.path"),
          })),
      },
    };
  }

  refLocale.current = currentLocale;

  return refActivities.current;
};

const query = graphql`query useActivitiesLatestQuery {
  allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "page"}, view: {eq: "activity"}, activity: {hidden: {ne: true}}}}
    sort: {frontmatter: {activity: {date: DESC}}}
    limit: 15
  ) {
    edges {
      node {
        frontmatter {
          locale
          path
          meta {
            title
            description
          }
          activity {
            highlight
            image {
              childImageSharp {
                gatsbyImageData(width: 400, height: 500, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}`;
