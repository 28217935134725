import { gsap, ScrollTrigger } from "gsap/all";

import { getProperty, observeResize, debounce } from "@gh/shared";

import customWrapPageElement from "./wrap-page-element";

import "./src/styles/main.scss";

gsap.registerPlugin(ScrollTrigger);

export const wrapPageElement = customWrapPageElement;

export const onRouteUpdate = ({ location, prevLocation }) => {
  const state = getProperty(location, "state");
  if (state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const anchor = document.location.hash.substring(1);
  const element = document.getElementById(anchor);
  if (element) {
    element.scrollIntoView();
  } else {
    const skipScroll = getProperty(location, "state.skipScroll");
    if (!skipScroll) {
      const currentPosition = getSavedScrollPosition(location);
      window.scrollTo(...(currentPosition || [0, 0]));
    }
  }

  return false;
};

export const onClientEntry = () => {
  // const observer = debounce(() => {
  //   // recalculate on dimension changes initially
  //   ScrollTrigger.refresh(true);
  //   // try to catch up with initial hash dependent
  //   // on calculations of `ScrollTrigger.refresh()`
  //   scrollToHash();
  //   // only way to detect if scroll triggers are
  //   // initialized with correct dimensions/sizes
  //   const instances = ScrollTrigger.getAll();
  //   if (instances.length > 0) {
  //     unobserve();
  //   }
  // }, 500);
  // const unobserve = observeResize(document.body, observer);
};
