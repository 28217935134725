import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useHeader = () => {
  const refHeader = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refHeader.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
    refHeader.current = edge;
  }

  refLocale.current = currentLocale;

  return refHeader.current;
};

const query = graphql`
  query useHeaderQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "header" } } }) {
      edges {
        node {
          frontmatter {
            locale
            header {
              contact {
                text_small
                text_large
              }
            }
          }
        }
      }
    }
  }
`;
