"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractLanguage = exports.detectLanguage = void 0;

var _misc = require("./misc");

var _object = require("./object");

const detectLanguage = () => {
  if (!_misc.isClient) {
    return null;
  }

  const {
    language,
    languages,
    userLanguage
  } = navigator;
  const detected = (0, _object.getProperty)(languages, "0") || userLanguage || language;

  if (typeof detected !== "string") {
    return null;
  }

  return detected.substring(0, 2).toLowerCase();
};

exports.detectLanguage = detectLanguage;

const extractLanguage = locales => {
  if (!_misc.isClient) {
    return null;
  } //
  // 1) `/(l1|l2|l3|...)/xxx`
  // 2) `/(l1|l2|l3|...)/`
  // 3) `/(l1|l2|l3|...)`
  //


  const group = "(".concat(locales.join("|"), ")");
  const path = window.location.pathname || "";
  const regex = new RegExp("\\/".concat(group, "(?=\\/|$)"));
  const match = path.match(regex);

  if (match === null) {
    return null;
  }

  return (0, _object.getProperty)(match, "1");
};

exports.extractLanguage = extractLanguage;