// extracted by mini-css-extract-plugin
export var black = "title-module--black--0b513";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var error = "title-module--error--e796a";
export var f1 = "title-module--f1--59985";
export var f2 = "title-module--f2--91a72";
export var h1 = "title-module--h1--1efad";
export var h2 = "title-module--h2--e6260";
export var h3 = "title-module--h3--b58e4";
export var h4 = "title-module--h4--2fc00";
export var h5 = "title-module--h5--9be4e";
export var h6 = "title-module--h6--fa21d";
export var lowercase = "title-module--lowercase--24463";
export var primary = "title-module--primary--fab37";
export var s1 = "title-module--s1--b5e6c";
export var s10 = "title-module--s10--5ffbd";
export var s2 = "title-module--s2--068e4";
export var s3 = "title-module--s3--5e59c";
export var s4 = "title-module--s4--03063";
export var s5 = "title-module--s5--08cb4";
export var s6 = "title-module--s6--d2c8e";
export var s7 = "title-module--s7--07c10";
export var s8 = "title-module--s8--1f3ff";
export var s9 = "title-module--s9--17ff6";
export var uppercase = "title-module--uppercase--d7dfb";
export var w1 = "title-module--w1--738eb";
export var w2 = "title-module--w2--88df1";
export var w3 = "title-module--w3--418fa";
export var w4 = "title-module--w4--3d0a1";
export var w5 = "title-module--w5--a5fca";
export var w6 = "title-module--w6--ce36e";
export var w7 = "title-module--w7--89dcf";
export var white = "title-module--white--cd568";