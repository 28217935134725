import PropTypes from "prop-types";

import { Navbar } from "./header/navbar";

export const Header = ({ showMenu, onToggle }) => (
  <header>
    <Navbar isOpen={showMenu} onToggle={onToggle} />
  </header>
);

Header.defaultProps = {
  showMenu: false,
  onToggle: () => {},
};

Header.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};
