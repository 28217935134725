// extracted by mini-css-extract-plugin
export var black = "text-module--black--38038";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var error = "text-module--error--a7fce";
export var f1 = "text-module--f1--e201f";
export var f2 = "text-module--f2--0f7d9";
export var f3 = "text-module--f3--7cea0";
export var grey = "text-module--grey--14ea9";
export var lowercase = "text-module--lowercase--0c8b6";
export var primary = "text-module--primary--22233";
export var s1 = "text-module--s1--f1f93";
export var s10 = "text-module--s10--ffc83";
export var s2 = "text-module--s2--285c9";
export var s3 = "text-module--s3--e301c";
export var s4 = "text-module--s4--babb7";
export var s5 = "text-module--s5--b916c";
export var s6 = "text-module--s6--2d51b";
export var s7 = "text-module--s7--60272";
export var s8 = "text-module--s8--4a6a2";
export var s9 = "text-module--s9--8bab4";
export var secondary = "text-module--secondary--be453";
export var uppercase = "text-module--uppercase--770b5";
export var w1 = "text-module--w1--a9f42";
export var w2 = "text-module--w2--5bd92";
export var w3 = "text-module--w3--b6cb5";
export var w4 = "text-module--w4--8878d";
export var w5 = "text-module--w5--5c8af";
export var w6 = "text-module--w6--13efd";
export var w7 = "text-module--w7--d760e";
export var white = "text-module--white--eb39d";