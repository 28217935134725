import { Container } from "@gh/shared";

import { Nav } from "./nav";
import { Ref } from "./ref";

import * as styles from "./bottom.module.scss";

export const Bottom = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Nav />
        <Ref />
      </Container>
    </div>
  );
};
