import { useMemo } from "react";

import { Text, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

export const Address = ({ layout }) => {
  const meta = useMeta();
  const address = getProperty(meta, "meta.contact.address");

  const text = useMemo(() => {
    return Array.isArray(address) ? address.join(", ") : null;
  }, [address]);

  return text ? <Text size="s5">{text}</Text> : null;
};
