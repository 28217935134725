// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var button = "modal-module--button--2ca2c";
export var close = "modal-module--close--67ef5";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "modal-module--container--7a153";
export var content = "modal-module--content--58ef4";
export var scroller = "modal-module--scroller--d464d";
export var svg = "modal-module--svg--7d30c";
export var wrapper = "modal-module--wrapper--70b7f";