"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "HTML", {
  enumerable: true,
  get: function () {
    return _html.HTML;
  }
});
Object.defineProperty(exports, "Markdown", {
  enumerable: true,
  get: function () {
    return _markdown.Markdown;
  }
});

var _html = require("./html");

var _markdown = require("./markdown");