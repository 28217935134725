// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var error = "field-module--error--ea1a5";
export var input = "field-module--input--03f88";
export var label = "field-module--label--ae047";
export var message = "field-module--message--e6e53";
export var required = "field-module--required--0d673";
export var submitted = "field-module--submitted--c3efa";
export var text = "field-module--text--ea248";
export var touched = "field-module--touched--779d8";
export var wrapper = "field-module--wrapper--b3b9c";