exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-activities-jsx": () => import("./../../../src/templates/activities.jsx" /* webpackChunkName: "component---src-templates-activities-jsx" */),
  "component---src-templates-activity-jsx": () => import("./../../../src/templates/activity.jsx" /* webpackChunkName: "component---src-templates-activity-jsx" */),
  "component---src-templates-apartments-jsx": () => import("./../../../src/templates/apartments.jsx" /* webpackChunkName: "component---src-templates-apartments-jsx" */),
  "component---src-templates-cuisine-jsx": () => import("./../../../src/templates/cuisine.jsx" /* webpackChunkName: "component---src-templates-cuisine-jsx" */),
  "component---src-templates-gtc-jsx": () => import("./../../../src/templates/gtc.jsx" /* webpackChunkName: "component---src-templates-gtc-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-hotel-jsx": () => import("./../../../src/templates/hotel.jsx" /* webpackChunkName: "component---src-templates-hotel-jsx" */),
  "component---src-templates-imprint-jsx": () => import("./../../../src/templates/imprint.jsx" /* webpackChunkName: "component---src-templates-imprint-jsx" */),
  "component---src-templates-offers-jsx": () => import("./../../../src/templates/offers.jsx" /* webpackChunkName: "component---src-templates-offers-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-request-jsx": () => import("./../../../src/templates/request.jsx" /* webpackChunkName: "component---src-templates-request-jsx" */),
  "component---src-templates-rooms-jsx": () => import("./../../../src/templates/rooms.jsx" /* webpackChunkName: "component---src-templates-rooms-jsx" */),
  "component---src-templates-spa-jsx": () => import("./../../../src/templates/spa.jsx" /* webpackChunkName: "component---src-templates-spa-jsx" */)
}

