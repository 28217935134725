import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";

import { useLocale } from "@gh/shared";

export const Languages = ({ children }) => {
  const { locales, defaultLocale, locale: currentLocale, setLocale } = useLocale();

  return locales.map((locale, index) => {
    const clickHandler = (event) => {
      event.preventDefault();
      setLocale(locale);
      navigate(path);
    };

    const isActive = locale === currentLocale;
    const separator = index < locales.length - 1 ? "/" : "";
    const path = locale === defaultLocale ? `/` : `/${locale}`;

    const link = <Link to={path} onClick={clickHandler} />;

    return children({ link, text: locale, separator, isActive, index });
  });
};

Languages.defaultProps = {
  children: ({ link, text, separator, index }) => (
    <link.type key={index} {...link.props}>
      {text} ${separator}
    </link.type>
  ),
};

Languages.propTypes = {
  children: PropTypes.func.isRequired,
};
