import { Text, EmailIcon, getProperty, useMatchMedia, XS, MD } from "@gh/shared";

import { useContact, useHeader } from "hooks";

import * as styles from "./contact.module.scss";

const MEDIA_QUERIES = [`(min-width: ${XS}px)`, `(min-width: ${MD}px)`];

export const Contact = () => {
  const header = useHeader();
  const contact = useContact();
  const [isXS, isMD] = useMatchMedia(MEDIA_QUERIES);

  const href = `#${getProperty(contact, "hash")}`;
  const text_small = getProperty(header, "header.contact.text_small");
  const text_large = getProperty(header, "header.contact.text_large");

  return (
    <a className={styles.wrapper} href={href}>
      <EmailIcon className={styles.icon} />
      <Text size={isXS ? "s2" : "s1"} transform="uppercase" wrapper="span" className={styles.text}>
        <span className={styles.small}>{text_small}</span>
        <span className={styles.large}>{text_large}</span>
      </Text>
    </a>
  );
};
