import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, setProperty, useLocale } from "@gh/shared";

export const useOffersLatest = () => {
  const refOffers = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refOffers.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
    refOffers.current = edge;
  }

  refLocale.current = currentLocale;

  return refOffers.current;
};

const query = graphql`
  query useOffersLatestQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "offers" } } }, limit: 15) {
      edges {
        node {
          frontmatter {
            locale
            offers {
              items {
                url
                path
                title
                categories
                image_small: image {
                  childImageSharp {
                    gatsbyImageData(width: 480, height: 720, layout: CONSTRAINED)
                  }
                }
                image_large: image {
                  childImageSharp {
                    gatsbyImageData(width: 600, height: 400, layout: CONSTRAINED)
                  }
                }
                summary {
                  text
                  price
                }
                details {
                  text
                  services
                }
              }

              categories {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;
