"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTransition = void 0;

var _react = require("react");

var _utils = require("../utils");

const PRE_ENTER = 0;
const ENTERING = 1;
const ENTERED = 2;
const PRE_EXIT = 3;
const EXITING = 4;
const EXITED = 5;
const UNMOUNTED = 6;
const STATES = ["preEnter", "entering", "entered", "preExit", "exiting", "exited", "unmounted"];

const startOrEnd = unmounted => unmounted ? UNMOUNTED : EXITED;

const useTransition = function () {
  let {
    enter = true,
    exit = true,
    preEnter,
    preExit,
    timeout,
    initialEntered,
    mountOnEnter,
    unmountOnExit,
    onChange
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [state, setState] = (0, _react.useState)(initialEntered ? ENTERED : startOrEnd(mountOnEnter));
  const latestState = (0, _react.useRef)(state);
  const timeoutId = (0, _react.useRef)();
  let enterTimeout, exitTimeout;

  if ((0, _utils.isObject)(timeout)) {
    enterTimeout = timeout.enter;
    exitTimeout = timeout.exit;
  } else {
    enterTimeout = exitTimeout = timeout;
  }

  const updateState = (0, _react.useCallback)(state => {
    clearTimeout(timeoutId.current);
    latestState.current = state;
    setState(state);

    if (typeof onChange === "function") {
      onChange({
        state: STATES[state]
      });
    }
  }, [onChange]);
  const endTransition = (0, _react.useCallback)(() => {
    let newState;

    switch (latestState.current) {
      case ENTERING:
      case PRE_ENTER:
        newState = ENTERED;
        break;

      case EXITING:
      case PRE_EXIT:
        newState = startOrEnd(unmountOnExit);
        break;

      default:
    }

    if (typeof newState !== "undefined") {
      updateState(newState);
    }
  }, [updateState, unmountOnExit]);
  const transitState = (0, _react.useCallback)(newState => {
    updateState(newState);

    switch (newState) {
      case ENTERING:
        if (enterTimeout >= 0) timeoutId.current = setTimeout(endTransition, enterTimeout);
        break;

      case EXITING:
        if (exitTimeout >= 0) timeoutId.current = setTimeout(endTransition, exitTimeout);
        break;

      case PRE_ENTER:
      case PRE_EXIT:
        timeoutId.current = setTimeout(() => transitState(newState + 1), 0);
        break;

      default:
    }
  }, [updateState, endTransition, enterTimeout, exitTimeout]);
  const toggle = (0, _react.useCallback)(toEnter => {
    const enterStage = latestState.current <= ENTERED;
    if (typeof toEnter !== "boolean") toEnter = !enterStage;

    if (toEnter) {
      if (!enterStage) {
        transitState(enter ? preEnter ? PRE_ENTER : ENTERING : ENTERED);
      }
    } else {
      if (enterStage) {
        transitState(exit ? preExit ? PRE_EXIT : EXITING : startOrEnd(unmountOnExit));
      }
    }
  }, [enter, exit, preEnter, preExit, transitState, unmountOnExit]);
  (0, _react.useEffect)(() => () => clearTimeout(timeoutId.current), []);
  return [STATES[state], toggle, endTransition];
};

exports.useTransition = useTransition;