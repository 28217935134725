import { Text } from "@gh/shared";

export const renderNavItem =
  ({ className, activeClassName, size = "s2", family = "f1" }) =>
  ({ link, text, index, isHighlighted }) =>
    (
      <link.type
        key={index}
        className={className}
        activeClassName={activeClassName}
        {...link.props}
      >
        <Text
          size={size}
          family={family}
          transform="uppercase"
          wrapper="span"
          weight={isHighlighted ? "w7" : "w4"}
        >
          {text}
        </Text>
      </link.type>
    );

export const renderLanguage =
  ({ className, size = "s3", family = "f2" }) =>
  ({ link, text, separator, isActive, index }) =>
    (
      <link.type key={index} className={className} {...link.props}>
        <Text
          size={size}
          wrapper="span"
          family={family}
          transform="uppercase"
          weight={isActive ? "w7" : "w4"}
        >
          {text.charAt(0)}
        </Text>
        {separator && (
          <Text size="s3" family="f2" wrapper="span" transform="uppercase" color="black">
            {"/"}
          </Text>
        )}
      </link.type>
    );
