"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _layout = require("./layout");

Object.keys(_layout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _layout[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _layout[key];
    }
  });
});

var _overlay = require("./overlay");

Object.keys(_overlay).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _overlay[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _overlay[key];
    }
  });
});

var _typography = require("./typography");

Object.keys(_typography).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _typography[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _typography[key];
    }
  });
});

var _structural = require("./structural");

Object.keys(_structural).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _structural[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _structural[key];
    }
  });
});