import { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { useTheme, useKeyPress, useMatchMedia, MD } from "@gh/shared";

import { Contact } from "./contact";
import { Header } from "./header";
import { Footer } from "./footer";
import { Popup } from "./popup";

import * as styles from "./layout.module.scss";

const MEDIA_QUERIES = [`(min-width: ${MD}px)`];

export const Layout = ({ children, location }) => {
  const [showMenu, setShowMenu] = useState(false);
  const isEscapePressed = useKeyPress("Escape");
  const [isMD] = useMatchMedia(MEDIA_QUERIES);
  const { theme } = useTheme();
  const wrapperRef = useRef();

  const wrapperClassName = useMemo(() => [styles.wrapper, styles[theme]].join(" "), [theme]);
  const htmlAttributes = useMemo(
    () => ({
      class: [styles[theme], showMenu ? styles.overflow : ""].filter(Boolean).join(" "),
    }),
    [theme, showMenu]
  );
  const toggleHandler = () => setShowMenu((showMenu) => !showMenu);

  useEffect(() => {
    if (isEscapePressed) {
      setShowMenu(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    if (isMD) {
      setShowMenu(false);
    }
  }, [isMD]);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  return (
    <div className={wrapperClassName} ref={wrapperRef}>
      <Helmet htmlAttributes={htmlAttributes} />
      <Header showMenu={showMenu} onToggle={toggleHandler} />
      <main className={styles.main}>{children}</main>
      <Footer />
      <Contact />
      <Popup />
    </div>
  );
};

Layout.defaultProps = {
  pageContext: {},
};

Layout.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
