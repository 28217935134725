// extracted by mini-css-extract-plugin
export var body = "popup-module--body--e6494";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var container = "popup-module--container--56188";
export var content = "popup-module--content--3c681";
export var entered = "popup-module--entered--de874";
export var entering = "popup-module--entering--771ce";
export var exited = "popup-module--exited--f58bd";
export var exiting = "popup-module--exiting--f9bf6";
export var header = "popup-module--header--bb72d";
export var image = "popup-module--image--63c46";
export var preEnter = "popup-module--preEnter--504d9";
export var svg = "popup-module--svg--4a3ae";
export var text = "popup-module--text--165c8";
export var wrapper = "popup-module--wrapper--9fa39";