"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = exports.arrayToObject = void 0;

var _object = require("./object");

const isArray = value => Array.isArray(value);

exports.isArray = isArray;

const arrayToObject = function (array) {
  let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "id";
  let value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

  if (!isArray(array)) {
    throw new Error("\"array\" must be an array!");
  }

  return Object.assign({}, ...array.map(item => {
    const obj = {};
    const prop = typeof key === "function" ? key(item) : (0, _object.isObject)(item) ? (0, _object.getProperty)(item, key) : item;
    const val = value !== undefined ? typeof value === "function" ? value(item) : value : (0, _object.isObject)(item) ? item : true;
    obj[prop] = val;
    return obj;
  }));
};

exports.arrayToObject = arrayToObject;