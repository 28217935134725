"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toUri = exports.isValid = void 0;

const isValid = value => {
  if (typeof value !== "string") {
    return false;
  }

  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\s0-9/.-]*$/.test(value);
};

exports.isValid = isValid;

const toUri = value => {
  return isValid(value) ? "tel:".concat(value.replace(/[^\d+]/g, "")) : null;
};

exports.toUri = toUri;