// extracted by mini-css-extract-plugin
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var fromLg = "container-module--from-lg--7cdd6";
export var fromMd = "container-module--from-md--42023";
export var fromSm = "container-module--from-sm--3f308";
export var fromXs = "container-module--from-xs--95020";
export var initial = "container-module--initial--be629";
export var toLg = "container-module--to-lg--0d466";
export var toMd = "container-module--to-md--ccf1a";
export var toSm = "container-module--to-sm--ef26c";
export var toXs = "container-module--to-xs--94595";