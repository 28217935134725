import { Text, getProperty, useMatchMedia, XS, MD } from "@gh/shared";

import { useMeta } from "hooks";

import * as styles from "./booking.module.scss";

const MEDIA_QUERIES = [`(min-width: ${XS}px)`, `(min-width: ${MD}px)`];

export const Booking = () => {
  const meta = useMeta();
  const [isXS, isMD] = useMatchMedia(MEDIA_QUERIES);

  const href = getProperty(meta, "meta.booking.url");
  const text_small = getProperty(meta, "meta.booking.text_small");
  const text_large = getProperty(meta, "meta.booking.text_large");

  return (
    <a className={styles.wrapper} href={href} rel="noreferrer" target="_blank">
      <Text
        size={isMD ? "s4" : isXS ? "s2" : "s1"}
        transform="uppercase"
        wrapper="span"
        className={styles.text}
      >
        <span className={styles.small}>{text_small}</span>
        <span className={styles.large}>{text_large}</span>
      </Text>
    </a>
  );
};
