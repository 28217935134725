import { LocaleProvider, ThemeProvider, ScrollProvider } from "@gh/shared";

import { Layout } from "theme";

const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale },
  } = props;

  return (
    <LocaleProvider locale={locale}>
      <ScrollProvider>
        <ThemeProvider>
          <Layout {...props}>{element}</Layout>
        </ThemeProvider>
      </ScrollProvider>
    </LocaleProvider>
  );
};

export default wrapPageElement;
