import { Link } from "gatsby";

import { Text, getProperty } from "@gh/shared";

import { usePagesByMenu } from "hooks";

import * as styles from "./nav-items.module.scss";

export const NavItems = () => {
  const pages = usePagesByMenu("misc");

  return pages.map((page) => {
    const path = getProperty(page, "path");
    const title = getProperty(page, "title");

    return (
      <Link key={path} to={path} className={styles.item} activeClassName={styles.active}>
        <Text size="s1" transform="uppercase" wrapper="span">
          {title}
        </Text>
      </Link>
    );
  });
};
