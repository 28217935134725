import { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { getProperty, useLocale } from "@gh/shared";

export const useMeta = () => {
  const refMeta = useRef(null);
  const refLocale = useRef(null);
  const { locale: currentLocale } = useLocale();
  const key = "allMarkdownRemark.edges";
  const results = useStaticQuery(query) || {};
  const edges = getProperty(results, key) || [];

  if (refMeta.current === null || refLocale.current !== currentLocale) {
    const [edge] = edges
      .map((edge) => getProperty(edge, "node.frontmatter"))
      .filter(({ locale }) => currentLocale === locale);
    refMeta.current = edge;
  }

  refLocale.current = currentLocale;

  return refMeta.current;
};

const query = graphql`
  query useMetaQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "meta" } } }) {
      edges {
        node {
          frontmatter {
            locale
            meta {
              company

              contact {
                address
                email {
                  text
                  url
                }
                phone {
                  text
                  url
                }
              }

              social_media {
                instagram {
                  text
                  url
                }
                facebook {
                  text
                  url
                }
              }

              booking {
                text_small
                text_large
                url
              }

              request {
                text_small
                text_large
                path
              }

              offers {
                label_included
              }

              activities {
                label_facts
                label_next
              }

              form {
                status {
                  sending
                }
                error {
                  required
                }
              }

              params {
                category
                offer
                page
              }

              empty_state {
                text
                label_button
              }

              pagination {
                label_page
              }

              navigation {
                label_more
                label_next
                label_prev
                label_back_home
                label_back_overview
              }

              error {
                title
                content
              }

              cookies {
                video_text
                video_button
              }
            }
          }
        }
      }
    }
  }
`;
