import { toEmailUri, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

import { Contact } from "./contact";

export const Email = () => {
  const meta = useMeta();
  const url = getProperty(meta, "meta.contact.email.url");
  const text = getProperty(meta, "meta.contact.email.text");

  return <Contact url={toEmailUri(url)} text={url} title={text} />;
};
