"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTheme = void 0;

var _react = require("react");

var _contexts = require("../contexts");

const useTheme = () => (0, _react.useContext)(_contexts.ThemeContext);

exports.useTheme = useTheme;