import { useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import {getProperty, LogoIcon} from "@gh/shared";

import { usePageByView } from "hooks";

import * as styles from "./logo.module.scss";

export const Logo = ({ animate }) => {
  const page = usePageByView("home");
  const path = getProperty(page, "path");

  const className = useMemo(
    () => [styles.wrapper, animate ? styles.animate : ""].join(" "),
    [animate]
  );

  return (
    <Link className={className} to={path}>
      <LogoIcon className={styles.icon} />
    </Link>
  );
};

Logo.defaultProps = {
  animate: true,
};

Logo.propTypes = {
  animate: PropTypes.bool.isRequired,
};
