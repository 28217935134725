// extracted by mini-css-extract-plugin
export var arrow = "button-module--arrow--69098";
export var arrows = "button-module--arrows--f70b6";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var icons = "button-module--icons--7b7b5";
export var separator = "button-module--separator--e44fe";
export var separatorLeft = "button-module--separatorLeft--a60ff";
export var separatorRight = "button-module--separatorRight--40541";
export var star = "button-module--star--5a559";
export var stars = "button-module--stars--1d4c5";
export var text = "button-module--text--5524a";
export var theme_arrow_back = "button-module--theme_arrow_back--58f56";
export var theme_flat = "button-module--theme_flat--80e27";
export var wrapper = "button-module--wrapper--f6be3";