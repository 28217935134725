// extracted by mini-css-extract-plugin
export var active = "overlay-module--active--214ee";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var item = "overlay-module--item--1c6d7";
export var language = "overlay-module--language--d60e1";
export var languages = "overlay-module--languages--aa936";
export var logo = "overlay-module--logo--82e25";
export var wrapper = "overlay-module--wrapper--8e055";